<template>
  <div>
    <a-modal
      title="添加/编辑小区"
      width="800px"
      :visible="visible"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :form="form" class="form">
        <a-row :gutter="24" style="margin-bottom:10px;">
          <a-col :span="12">
            <a-form-item labelAlign="left" label="小区封面" :required="true" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <upload ref="upload" :length="1" :cutImg="false" :imgs="image" uploadTest="封面" :fixedNumber="[1,1]" @uploadSuccess="headSuccess"></upload>
            </a-form-item>
          </a-col>
          <a-col :span="12" style="position: absolute;right:0px;top:-20px;">
            <el-amap
                ref="map"
                :vid="'amapDemo'"
                :center="center"
                :zoom="zoom"
                :events="events"
                class="amap-demo"
                style="height: 170px;">
              <el-amap-marker :position="position" >
            </el-amap-marker>
            </el-amap>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="小区名称">
              <a-input style="width:80%;" placeholder="请输入小区名称" v-decorator="['name', {rules: [{required: true, message: '请输入小区名称'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :required="true" labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="详细地址">
              <el-amap-search-box
                class="search-box"
                :search-option="searchOption"
                :on-search-result="onSearchResult"
              ></el-amap-search-box>
              {{ address }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="物业名称">
              <a-input style="width:80%;" placeholder="请输入物业名称" v-decorator="['property', {rules: [{required: true, message: '请输入物业名称'}]}]"/>
            </a-form-item>
            
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="单价">
              <a-input-number style="width:80%;" placeholder="请输入单价" v-decorator="['unitPrice', {rules: [{required: true, message: '请输入单价'}]}]"/>&ensp;元/㎡
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="在售房源">
              <a-input-number style="width:80%;" placeholder="请输入在售房源" v-decorator="['onSale', {rules: [{required: true, message: '请输入在售房源'}]}]"/>&ensp;套
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="在租房源">
              <a-input-number style="width:80%;" placeholder="请输入在租房源" v-decorator="['onLease', {rules: [{required: true, message: '请输入在租房源'}]}]"/>&ensp;套
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="建造年代">
              <a-input-number style="width:80%;" placeholder="请输入建造年代" v-decorator="['construction', {rules: [{required: true, message: '请输入建造年代'}]}]"/>&ensp;年
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="占地面积">
              <a-input-number style="width:80%;" placeholder="请输入占地面积" v-decorator="['areaCovered', {rules: [{required: true, message: '请输入占地面积'}]}]"/>&ensp;㎡
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="绿化率">
              <a-input-number style="width:80%;" placeholder="请输入绿化率" v-decorator="['greeningRate', {rules: [{required: true, message: '请输入绿化率'}]}]"/>&ensp;%
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="容积率">
              <a-input-number style="width:80%;" placeholder="请输入容积率" v-decorator="['plotRatio', {rules: [{required: true, message: '请输入容积率'}]}]"/>&ensp;%
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :required="true" :labelCol="labelCol" :wrapperCol="wrapperCol" label="建地类型">
              <a-radio-group  button-style="solid" v-decorator="['landType']">
                <a-radio-button value="普通住宅">
                  普通住宅
                </a-radio-button>
                <a-radio-button value="商业住宅">
                  商业住宅
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import Upload from "@/components/upload/Upload"
import moment from 'moment'
import store from '@/store'
import matchFun from '@/utils/matchFun'
import { mapState } from 'vuex'
export default {
  components: {
    Upload
  },
  data () {
    return {
      visible: false,
      confirmLoading: false,
      matchFun: matchFun,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      item: null,
      address: '',
      image: [],
      form: this.$form.createForm(this),
      center: [121.5273285, 31.21515044],
      zoom: 12,
      position: [121.5273285, 31.21515044],
      events: {
          init(o){
              console.log(o.getCenter());
          },
          zoomchange: (e) => {
              console.log(e);
          },
          zoomend: (e) => {
              //获取当前缩放zoom值
              console.log(this.$refs.map.$$getInstance().getZoom());
              console.log(e);
          },
          click: e => {
              const { lng, lat } = e.lnglat
              this.position = [lng, lat]
              console.log(e);
          }
      },
      searchOption: {
        city: '杭州',
        citylimit: false
      }
    }
  },
  computed: {
    ...mapState({
    })
  },
  methods: {
    moment,
    showModal (item) {
      this.item = null
      this.image = []
      if (item !== null) {
        this.item = item
        this.address = item.address
        setTimeout(() => {
          this.form.setFieldsValue({
            name: item.name,
            unitPrice: item.unitPrice,
            property: item.property,
            onSale: item.onSale,
            onLease: item.onLease,
            construction: item.construction,
            areaCovered: item.areaCovered,
            greeningRate: item.greeningRate,
            plotRatio: item.plotRatio,
            landType: item.landType
          })
          this.position = [item.longitude, item.latitude]
          this.center = [item.longitude, item.latitude]
          this.image.push(this.matchFun.randomNumFile('封面.jpg', item.image))
        })
      } else {
        setTimeout(() => {
          this.form.setFieldsValue({
            landType: '普通住宅'
          })
        })
      }
      this.visible = true
    },
     // 高德地图
    onSearchResult(pois) {
      let latSum = 0
      let lngSum = 0
      if (pois.length > 0) {
        pois.forEach(poi => {
          let { lng, lat } = poi
          lngSum += lng
          latSum += lat
          this.position = [poi.lng, poi.lat]
        })
        let mapcenter = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length
        }
        this.center = [mapcenter.lng, mapcenter.lat]
        this.address = pois[0].name
        this.position = [pois[0].lng, pois[0].lat]
      }
    },
    headSuccess (e) {
      this.image = e
      console.log(this.image)
    },
    handleOk (e) {
      if (this.image.length === 0) {
        this.$message.warn('请上传封面！')
        return
      }
      console.log(this.image)
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          var upData = {...values}
          upData.id = this.item !== null ? this.item.id : null
          upData.address = this.address
          upData.longitude = this.position[0]
          upData.latitude = this.position[1]
          if (this.image[0].response.data.qiniuUrl) {
            upData.image = this.image[0].response.data.qiniuUrl
          } else {
            upData.image = this.image[0].url
          }
          
          this.$api.village.save(upData).then(res => {
            if (res.status === 0) {
              this.$message.success('保存成功')
              this.$emit('ok')
              this.handleCancel()
            } else {
              this.$message.warn(res.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    handleCancel (e) {
      this.visible = false
      this.$$emit('ok')
    }
  }
}
</script>
<style scoped>

.search-box {
  width: 80%;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 32px;
}

</style>
